import React from "react";

import Button from 'components/Button'
import {FixedRating} from "components/Rating";
import {useNavigate} from "react-router-dom";
import {formatCurrency} from 'utils/functions'
import {
    ContainerHotel,
    ContainerPrice,
    ContentHotel,
    HotelImages,
    InformationHotel
} from "./styles";

import {useApp} from "../../../contexts/AppContext";
import {changeSelectedHotelAction, changeSelectedRoomHotelAction} from "../../../contexts/reducers/hotels/actions";
import {isChannel} from "../../../utils";
import {useGenialApp} from "../../../contexts/GenialAppContext";

export const BoxHotel = ({data}) => {
    const navigate = useNavigate()

    const { hotelState, hotelDispatch } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { offersHotel } = hotelState

    const room = data.rooms[0]?.name;
    const price = data.rooms[0]?.amount?.BRL
    const points = data.rooms[0]?.amount?.ENVISION

    const externalProperties = JSON.parse(data?.rooms[0]?.externalProperties)

    const handleSelectHotel = () => {
        hotelDispatch(changeSelectedHotelAction(data))
        hotelDispatch(changeSelectedRoomHotelAction({}))

        navigate(`/hotel-page`)
    }

    return (
        <ContainerHotel>
            <div className="d-flex">
                <div className="image">
                    <HotelImages src={ data.images[0]?.url } alt={ data.name } />
                </div>

                <InformationHotel>
                    <ContentHotel>
                        <p className="title-hotel">{ data.name }</p>
                        <div className="stars">
                            <FixedRating value={ data.stars } size="small" />
                        </div>

                        <p className="info">{ externalProperties["Refundable"] === "True"
                            ?  'Com Reembolso' : 'Sem Reembolso' }</p>
                        <p className="mapa">
                            {data.address.street}
                        </p>

                    </ContentHotel>
                    <ContainerPrice>
                        <p style={{fontWeight:700, marginBottom:"10px 0px" }}> { room } </p>
                        <p className="diaria">diárias a partir de</p>
                        { offersHotel === "cash" && <p className="price"> { formatCurrency(price) } </p> }
                        { offersHotel === "points" && <p className="price">{ parseFloat(points).toLocaleString() } <span>Pts</span></p> }

                        <p className="taxa">taxas inclusas</p>
                    </ContainerPrice>

                    <div className="text-center mt-2 mb-1">
                        <Button width="160px" onClick={ handleSelectHotel }>Continuar</Button>
                    </div>
                </InformationHotel>
            </div>
        </ContainerHotel>
    )
}
