import {createContext, useState, useContext, useEffect} from "react";
import {useLocation} from "react-router-dom";

import {LoaderSearchImage} from "../../components/Loader";
import $ from "jquery";
import axios from "axios";

export const AuthContext = createContext('')

export const AuthSafraProvider = ({children}) => {

    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const customerId = params.get('uuid')
    const customerBalance = params.get('balance')

    if (validateCustomerData()) {
        localStorage.setItem('customer_parent', customerId)
        localStorage.setItem('balance_parent', customerBalance)
    }

    function validateCustomerData() {
        let result = false

        if(! localStorage.getItem('customer_parent') || localStorage.getItem('customer_parent').length < 9 || ! localStorage.getItem('balance_parent')) {
            result = true
        }

        return result
    }

    useEffect(() => {

        const login = async () => {
            setLoading(true)

            localStorage.setItem("customer_envision_v1",
                JSON.stringify([
                    {
                        "phoneNumber": "41984054350",
                        "name": "Andre Cardoso",
                        "email": "andrecardosodev@gmail.com",
                        "document": "30585494070",
                        "identifier": "cpf"
                    }
                ])
            );

            const data = $.param({
                'client_id':  process.env.REACT_APP_CLIENT_ID,
                'client_secret':  process.env.REACT_APP_CLIENT_SECRET,
                'grant_type': 'client_credentials'
            })

            var config = {
                method: 'post',
                url: process.env.REACT_APP_API_URL,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data : data
            };

            axios(config).then(response => {
                if (response.status === 200) {
                    const token = response.data.access_token;
                    localStorage.setItem("token", token);
                    setLoading(false)
                }

            }).catch(error => console.log('ERROR', error))
        }

        login()

    },[])

    if(loading) return <LoaderSearchImage />

return (
        <AuthContext.Provider value={{customerId}}>
            {children}
        </AuthContext.Provider>
        )
}

export const useAuth = () => useContext(AuthContext);
